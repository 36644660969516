// alert.js
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// auth.js
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RECOVERY_PASSWORD = 'RECOVERY_PASSWORD';
export const CHANGE_PROFILE = 'CHANGE_PROFILE';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_NOORGANIZATION = 'USER_NOORGANIZATION';
export const REGISTER = 'REGISTER';
export const LOAD_REGISTER = 'LOAD_REGISTER';

// data.js
export const REFRESH_DATA = 'REFRESH_DATA';
export const LOAD_DATA = 'LOAD_DATA';
export const ADD_DATA = 'ADD_DATA';
export const EDIT_DATA = 'EDIT_DATA';
export const DELETE_DATA = 'DELETE_DATA';
export const EXPORT_DATA = 'EXPORT_DATA';
export const UPLOAD_DATA = 'UPLOAD_DATA';
export const CRUD_FAIL = 'CRUD_FAIL';

// master.js
export const LOAD_USER = 'LOAD_USER';
export const LOAD_ROLE = 'LOAD_ROLE';
export const LOAD_MODULE = 'LOAD_MODULE';
export const LOAD_ITEM = 'LOAD_ITEM';
export const LOAD_EXPEDITION = 'LOAD_EXPEDITION';
export const LOAD_VEHICLETYPE = 'LOAD_VEHICLETYPE';
export const LOAD_CATEGORY = 'LOAD_CATEGORY';
export const LOAD_PACKING = 'LOAD_PACKING';
export const LOAD_UOM = 'LOAD_UOM';
export const LOAD_VENDOR = 'LOAD_VENDOR';
export const LOAD_PALLET = 'LOAD_PALLET';
export const LOAD_LOCATION = 'LOAD_LOCATION';
export const LOAD_BATCH = 'LOAD_BATCH';
export const LOAD_SHIPPING = 'LOAD_SHIPPING';
export const LOAD_ORDERDETAIL = 'LOAD_ORDERDETAIL';
export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_RECEIVING = 'LOAD_RECEIVING';
export const LOAD_COUNTRY = 'LOAD_COUNTRY';
export const LOAD_DISTRICT = 'LOAD_DISTRICT';
export const LOAD_PROVINCE = 'LOAD_PROVINCE';
export const LOAD_TERMOFPAYMENT = 'LOAD_TERMOFPAYMENT';
export const LOAD_SELLER = 'LOAD_SELLER';
export const LOAD_CITY = 'LOAD_CITY';
export const LOAD_GATE = 'LOAD_GATE';
export const LOAD_RAWMATERIAL = 'LOAD_RAWMATERIAL';
export const LOAD_ORDERFILTER = 'LOAD_ORDERFILTER';
export const LOAD_CUSTOMERSTATUS = 'LOAD_CUSTOMERSTATUS';
export const LOADED_TRANSACTION_PALLET = 'LOADED_TRANSACTION_PALLET';
export const LOAD_DELIVERYASSIGNMENT = 'LOAD_DELIVERYASSIGNMENT';
export const LOAD_DELIVERYASSIGNMENTDETAIL = 'LOAD_DELIVERYASSIGNMENTDETAIL';
export const LOAD_CERTIFICATEDETAIL = 'LOAD_CERTIFICATEDETAIL';
export const LOAD_CERTIFICATEOFANALYSIS = 'LOAD_CERTIFICATEOFANALYSIS';
export const LOAD_CHARTOFACCOUNT = 'LOAD_CHARTOFACCOUNT';
export const LOAD_ITEMCONSUMPTIONTYPE = 'LOAD_ITEMCONSUMPTIONTYPE';

// ice.js
export const LOAD_FLEETCATEGORY = 'LOAD_FLEETCATEGORY';
export const LOAD_FLEETTYPE = 'LOAD_FLEETTYPE';
export const LOAD_DRIVER = 'LOAD_DRIVER';
export const LOAD_FLEET = 'LOAD_FLEET';
export const LOAD_INDUSTRY = 'LOAD_INDUSTRY';
export const LOAD_CUSTOMER = 'LOAD_CUSTOMER';
export const LOAD_WAREHOUSE = 'LOAD_WAREHOUSE';
export const LOAD_GROUP = 'LOAD_GROUP';
export const LOAD_SECTOR = 'LOAD_SECTOR';
export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const LOAD_POOL = 'LOAD_POOL';
export const LOAD_ITEMTYPE = 'LOAD_ITEMTYPE';
export const LOAD_LOANTYPE = 'LOAD_LOANTYPE';
export const LOAD_PRODUCTION = 'LOAD_PRODUCTION';
export const LOAD_ROUTE = 'LOAD_ROUTE';
export const LOAD_OUTSTANDING_SHIPMENT = 'LOAD_OUTSTANDING_SHIPMENT';
export const LOAD_READY_ORDER = 'LOAD_READY_ORDER';
export const LOAD_UNPAID_INVOICE = 'LOAD_UNPAID_INVOICE';
export const LOAD_COST_CENTER = 'LOAD_COST_CENTER';
export const SYNC_DATE = 'SYNC_DATE';
export const ORDER_STATUS = 'ORDER_STATUS';
export const SHIPMENT_STATUS = 'SHIPMENT_STATUS';

export const LOAD_ORGANIZATION = 'LOAD_ORGANIZATION';
export const LOAD_INVITATION = 'LOAD_INVITATION';
export const LOAD_REQUEST = 'LOAD_REQUEST';
export const ORGANIZATION_REQUEST = 'ORGANIZATION_REQUEST';
export const ORGANIZATION_STATUS = 'ORGANIZATION_STATUS';
export const LOAD_REQUESTNAME = 'LOAD_REQUESTNAME';
export const LOAD_LISTORGANIZATION = 'LOAD_LISTORGANIZATION';

export const LOAD_TRANSACTION_WAREHOUSE = 'LOAD_TRANSACTION_WAREHOUSE';
export const LOAD_TRANSACTION_PALLET = 'LOAD_TRANSACTION_PALLET';
export const LOAD_TRANSACTION_LOCATION = 'LOAD_TRANSACTION_LOCATION';
export const LOAD_ITEM_BATCH = 'LOAD_ITEM_BATCH';

export const LOAD_RAWMATERIAL_WAREHOUSE = 'LOAD_RAWMATERIAL_WAREHOUSE';
export const LOAD_RAWMATERIAL_PALLET = 'LOAD_RAWMATERIAL_PALLET';
export const LOAD_RAWMATERIAL_LOCATION = 'LOAD_RAWMATERIAL_LOCATION';
export const LOAD_RAWMATERIAL_BATCH = 'LOAD_RAWMATERIAL_BATCH';

// dashboard.js
export const DASHBOARD_ACHIEVEMENT = 'DASHBOARD_ACHIEVEMENT';
export const DASHBOARD_FLEETORDER = 'DASHBOARD_FLEETORDER';
export const DASHBOARD_FLEETSTATUS = 'DASHBOARD_FLEETSTATUS';
export const DASHBOARD_DOCUMENTSTATUS = 'DASHBOARD_DOCUMENTSTATUS';

// detail
export const LOAD_SHIPPINGDETAIL = 'LOAD_SHIPPINGDETAIL';
export const LOAD_ITEMADJUSTMENT = 'LOAD_ITEMADJUSTMENT';
// HOME
export const HOME_SUMMARY = 'HOME_SUMMARY_DATA';
export const HOME_SEARCH_ITEM = 'HOME_SEARCH_ITEM';
export const HOME_WAREHOUSE_CAPACITY = 'HOME_WAREHOUSE_CAPACITY';
export const HOME_TOP_ITEM_TOTAL = 'HOME_TOP_ITEM_TOTAL';
export const HOME_SPK_PENDING_LIST = 'HOME_SPK_PENDING_LIST';
export const HOME_LIST_ITEM = 'HOME_LIST_ITEM';

//ISMOBILE
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
